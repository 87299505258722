.advSlider {
  padding-top: 30px;

  .ads-main {
    border-radius: 12px;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  // Slick dots
  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 12px 0;
    list-style-type: none;

    li {
      margin: 0 2.5px;
      transform: scale(1);
    }

    button {
      display: block;
      width: 8px;
      height: 8px;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: var(--primary-color);
      opacity: 0.2;

      &::before {
        display: none;
      }
    }

    li.slick-active button {
      opacity: 1;
      width: 21px;
      border-radius: 25px;
    }
  }

  // Iframe css
  // iframe {
  //   width: 100%;
  //   height: 100%;
  //   background-color: red;
  //   #google_image_div{
  //     background-color: yellow;
  //   }
  // }
}
.adSize{
 min-height: 280px;
}

@media only screen and (max-width: 750px) {
  .adSize{
    min-height: 30px;
   }
}