.create-school-button{
        color: #fff;
        background-color: #057ebb;
        border: none;
        border-radius: 3px;
        padding: 10px 12px;
        outline: none;
        font-size: 14px;
        margin: 0 1px;
}
.cancel-admin-button{
    background-color: #d9534f;
    border: none;
    outline: none;
    color: white;
    padding: 5px 15px;
    border-radius: 3px;
    margin-right: 25%;
    margin-top: 5%;
}
.runButton {
  color: #fff;
  background-color:  var(--success-color);
  border: none;
  width: 170px;  
  height: 35px;
  border-radius: 3px;
  padding: 5px 7px;
  outline: none;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 12px;
  font-weight: bold;
  }
  
.flex-course {
  display: flex;
  flex-wrap: wrap;
  align-items: center; 
  gap: 10px; 
}
.school-shade {
  position: relative;
}
.custom-modal-class {
  /* position: fixed; */
  width: 600px;
  top: 30%;
  transform: translate(-50%, -50%);
}
.uploadContainer {
  display: flex; 
  align-items: center; 
  gap: 10px; 
}

.calButton {
  color: #fff;
  background-color: #057ebb;
  border: none;
  border-radius: 3px;
  padding: 8px 12px;
  outline: none;
  font-size: 14px;
}

@media (max-width: 768px) {
  .uploadContainer {
    flex-direction: column; 
    gap: 15px; 
  }
}
