.parentLadder{
    .studentSelect{
        max-width: 375px;
        select{
            background-color: var(--white-color);
        }
    }
    .chekbox-container{
        cursor: auto;
    }
    .ladder-main {
        .interest-main {
          margin-top: 10px;
          border: 0.76621px solid rgba(0, 0, 0, 0.04);
          box-shadow: 0px 3.06484px 6.12968px rgba(0, 0, 0, 0.04), 0px 0px 1.53242px rgba(0, 0, 0, 0.06), 0px 0px 0.76621px rgba(0, 0, 0, 0.04);
          border-radius: 6.12968px;
      
          .card-body {
            padding-top: 35px;
            padding-bottom: 24px;
          }
      
          .row {
            margin: 0 -35px;
          }
      
          .row [class*='col-'] {
            padding: 0 35px;
          }
      
          label {
            font-weight: var(--font-medium);
            font-size: var(--font14);
            line-height: 1.1;
            color: #2C121B;
          }
      
          .shadow-box {
            border: 1px solid rgba(0, 0, 0, 0.08);
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            margin-bottom: 12px;
          }
          .tagLineHeader{
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: var(--font20);
            font-weight: var(--font-bold);
            text-decoration: underline;
            .tagText{
                font-size: var(--font14);
                font-weight: var(--font-normal);
            }
          }
        }
      }
}