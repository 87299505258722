.cardNumberBlock {
    border: 0.577276px solid #FFFFFF;
    border-radius: 15px;
    margin-bottom: 10px;
    cursor: pointer;

    &.blue {
        background-color: #508AFF;
    }

    &.green {
        background-color: #6FCC47;
    }

    &.yellow {
        background-color: #FFC355;
    }

    &.red {
        background-color: #FF6363;
    }

    .cardBlockInner {
        display: flex;
        justify-content: space-between;
        padding: 20px 18px;
    }

    .textWrap {
        display: flex;
    }

    .number {
        width: 40px;
        height: 40px;
        font-weight: var(--font-medium);
        font-size: var(--font23);
        line-height: 1.2;
        color: var(--white-color);
        background-color: #6296FF;
        box-shadow: 0px 3.57818px 4.77091px rgba(0, 0, 0, 0.07);
        border-radius: 11.9273px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border: 1px solid rgba(255, 255, 255, 0.4);

        &.blue {
            background-color: #6296FF;
        }

        &.green {
            background-color: #6FCC47;
        }

        &.yellow {
            background-color: #FFC355;
        }

        &.red {
            background-color: #FF6363;
        }
    }

    .cardText {
        font-weight: 500;
        font-size: var(--font16);
        line-height: 1.2;
        color: var(--white-color);
    }

    .selectIcon {
        width: 15px;
        height: 15px;
        padding: 2px;
        color: #6FCC47;
        font-weight: var(--font-medium);
        font-size: var(--font10);
        line-height: 1.2;
        color: #6FCC47;
        background-color: var(--white-color);
        box-shadow: 0px 1.22182px 1.62909px rgba(0, 0, 0, 0.07);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-right: 10px;
        // border: 1px solid rgba(255,255, 255, 0.4);
    }
}