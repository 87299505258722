.textFieldfeedback .MuiInput-root:after{
 border-bottom:1px solid black
}
.textFieldfeedback .Mui-focused{
    color: #6f6a6a !important;
    font-weight: bold;
    font-size: 16px;  
}
.textFieldfeedback .MuiInputLabel{
    padding: 0 15px;
}
.student-list-modal-container{
    display: grid;
    grid-template-columns: repeat(1 , 1fr);
}
.nudge-section-dropdown {
    margin: 15px 0;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.section-select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.template-list-section {
    margin-top: 15px;
}

.template-item {
    padding: 10px;
    border: 1px solid #eee;
    margin: 5px 0;
    cursor: pointer;
}

.template-item:hover {
    background-color: #f5f5f5;
}

.template-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.modalAccordion {
    background-color: #4a2748;
    color: white;
    padding: 15px;
    display: flex;
    border: 1px solid #ddd;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    /* margin-top: 10px; */
}

.section-option {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.section-option:hover {
    background-color: #f5f5f5;
}

.template-list {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #eee;
}

.template-item {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.template-item:hover {
    background-color: #f5f5f5;
}
.template-section {
    margin-top: 0;
  }
  
  .template-item:last-child {
    border-bottom: none;
  }
  
  .template-item:hover {
    background-color: #f5f5f5;
  }