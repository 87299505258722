.template-grid-container{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 40px;
}
.single-template-container{
    border: 1px solid #ddd;
    padding: 10px 15px;
    margin:10px 0px;
    cursor: pointer;
}
.subject-text{
    font-size: 18px;
    font-weight: 700;
    color: #000;
}
.subject-text:hover{
    text-decoration: underline;
    cursor: pointer;
}
.template-separator{
    border-top: 1px solid #000;
}
.language-select{
    width:100%;
    height: 50px;
}
.language-select:focus{
    box-shadow: inset 0 0px 0px #000008, 0 0 6px #66AFE9;
    border: 1px solid #b0a8a8;
    outline: none;   
}
.select-option{
    padding: 10px;
}
.subject-input{
    width: 100%;
    height: 70px;
    border: 1px solid #b0a8a8;
    padding:0px 10px
}
.subject-input:focus{
    box-shadow: inset 0 0px 0px #000008, 0 0 6px #66AFE9;
    border: 1px solid #b0a8a8;
    outline: none;    
}
.delete-template-button{
    width: 200px;
    height: 40px;
    background-color: white;
    color: var(--selected-primary-button-color);
    font-weight:bold ;
}
.save-template-button{
    width: 200px;
    height: 40px;
    background-color:var(--selected-primary-button-color);
    color: white;
    font-weight:bold ;
}
.feedback-input{
width: 100%;
height: 40px;
border: none;
outline: none;
}
/* .template-list-container {
  padding: 15px;
}

.template-item {
  border: 1px solid #e0e0e0;
  background-color: #e8f5e9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.template-item.selected {
  border-color: #4CAF50;
  box-shadow: 0 2px 8px rgba(76, 175, 80, 0.1);
}

.template-item:hover {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.template-subject {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.template-body {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.language-buttons {
  display: flex;
  gap: 10px;
  margin: 5px 0;
}

.lang-btn {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  min-width: 45px;
  text-align: center;
}

.lang-btn.active {
  background: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.lang-btn:hover:not(.active) {
  background: #e8f5e9;
  border-color: #4CAF50;
}

.lang-btn.active:hover {
  background: #43A047;
}


.template-creation-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
}

.template-creation-header {
  border-bottom: 2px solid #333;
  padding: 12px 16px;
  margin: -20px -20px 20px;
  background: #f8f8f8;
  border-radius: 8px 8px 0 0;
}

.template-creation-header h2 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.template-form-group {
  margin-bottom: 20px;
}

.language-select, 
.section-select {
  width: 100%;
  height: 45px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 15px;
  transition: all 0.2s ease;
}

.language-select:focus,
.section-select:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
  outline: none;
}

.subject-input,
.body-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.body-input {
  min-height: 120px;
  resize: vertical;
}

.subject-input:focus,
.body-input:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
  outline: none;
}

.feedback-section {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-top: 15px;
}


.template-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.save-template-button,
.delete-template-button {
  padding: 10px 24px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.2s ease;
  min-width: 150px;
}

.save-template-button {
  background: #4CAF50;
  color: white;
  border: none;
}

.save-template-button:hover {
  background: #43A047;
}

.delete-template-button {
  background: white;
  color: #dc3545;
  border: 1px solid #dc3545;
}

.delete-template-button:hover {
  background: #dc3545;
  color: white;
}

.message-templates-header {
    padding: 20px;
    background-color: #FAF9F8;
}

.message-templates-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.tab-container {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #333;
}

.tab-button {
    padding: 12px 20px;
    cursor: pointer;
    flex: 1;
    text-align: center;
    background-color: #2C121B;
    color: white;
    border: none;
    transition: background-color 0.3s;
    font-size: 16px;
    font-weight: bold;
}

.tab-button.active {
    background-color: #008000;
}

.tab-button:hover {
    opacity: 0.9;
} */