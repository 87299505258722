.parnetAccountWrap {
    height: 100vh;

    .logoImg {
        margin-top: 45px;
        margin-bottom: 40px;
        width: 180px;
    }

    .parnetAccountInner {
        background: url('https://siembra-frontend.s3.us-west-1.amazonaws.com/images/forgot-pswd-bg.png') no-repeat center;
        background-size: cover;
        // height: 395px;
        height: 48vh;
    }

    .parnetAccountBlockInner {
        .boxWrap {
            max-width: 745px;
            margin-left: auto;
            margin-right: auto;
        }

        .boxwrapInner {
            max-width: 745px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
            padding: 45px 50px 32px 50px;

            @media(max-width : 767px) {
                padding: 45px 20px 32px 20px;
            }

            background: var(--white-color);
            border: 1px solid rgba(138, 165, 182, 0.2);
            box-shadow: 0px 8px 8px rgba(141, 161, 172, 0.15);
            border-radius: 15px;

            .accountTitle {
                max-width: 450px;
                margin: 0 auto;
                margin-bottom: 20px;
                font-weight: var(--font-bold);
                font-size: var(--font32);
                line-height: 1.2;
                letter-spacing: -0.3px;
                color: var(--color6);

            }
        }
    }

    .btnWrap {
        @media(max-width: 767px) {
            padding: 0 15px;
        }

    }

    .btnWrapper {
        display: flex;
        margin: 0 -15px;
        padding-bottom: 20px;

        @media(max-width: 767px) {
            flex-direction: column;
        }

        .btnInner {
            flex: 1;
            padding: 0 15px;
        }

        .stdFormBtn {
            margin-bottom: 12px;

            @media(max-width: 767px) {
                flex-direction: column;
            }
        }
    }


}