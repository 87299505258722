.parentGoal {

    // login user data
    .loginUser {
        font-weight: var(--font-normal);
        font-size: var(--font20);
        line-height: 1.2;
        color: var(--secondary-font-color);
        margin-bottom: 10px;

        .logUserName {
            font-weight: var(--font-bold);
        }
    }

    // goal select
    .goalSelect {
        max-width: 375px;

        select {
            background-color: rgba(0, 74, 173, 0.1);
        }
    }

    // google add
    .googleAddWrap {
        .advSlider {
            padding-top: 0;
        }

        margin-bottom: 25px;
    }

    // Goal Summary
    .goalSummaryWrap {
        .mainTitle {
            margin-bottom: 15px;
        }

        // Goal Cards
        .goalCardWrap {
            background: #F3F3F3;
            border: 1px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 21px;
            padding: 20px 20px 10px 20px;

            .row {
                margin: 0 -8px;
            }

            .row [class*='col-'] {
                padding: 0 8px;
            }

            // .cardNumberBlock {
            //     border: 0.577276px solid #FFFFFF;
            //     border-radius: 15px;
            //     margin-bottom: 10px;

            //     &.blue {
            //         background-color: #508AFF;
            //     }

            //     &.green {
            //         background-color: #6FCC47;
            //     }

            //     &.yellow {
            //         background-color: #FFC355;
            //     }

            //     &.red {
            //         background-color: #FF6363;
            //     }

            //     .cardBlockInner {
            //         display: flex;
            //         justify-content: space-between;
            //         padding: 20px 18px;
            //     }

            //     .textWrap {
            //         display: flex;
            //     }

            //     .number {
            //         width: 40px;
            //         height: 40px;
            //         font-weight: var(--font-medium);
            //         font-size: var(--font23);
            //         line-height: 1.2;
            //         color: var(--white-color);
            //         background-color: #6296FF;
            //         box-shadow: 0px 3.57818px 4.77091px rgba(0, 0, 0, 0.07);
            //         border-radius: 11.9273px;
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         margin-right: 10px;
            //         border: 1px solid rgba(255, 255, 255, 0.4);

            //         &.blue {
            //             background-color: #6296FF;
            //         }

            //         &.green {
            //             background-color: #6FCC47;
            //         }

            //         &.yellow {
            //             background-color: #FFC355;
            //         }

            //         &.red {
            //             background-color: #FF6363;
            //         }
            //     }

            //     .cardText {
            //         font-weight: 500;
            //         font-size: var(--font16);
            //         line-height: 1.2;
            //         color: var(--white-color);
            //     }

            //     .selectIcon {
            //         width: 15px;
            //         height: 15px;
            //         padding: 2px;
            //         color: #6FCC47;
            //         font-weight: var(--font-medium);
            //         font-size: var(--font10);
            //         line-height: 1.2;
            //         color: #6FCC47;
            //         background-color: var(--white-color);
            //         box-shadow: 0px 1.22182px 1.62909px rgba(0, 0, 0, 0.07);
            //         border-radius: 50%;
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         // margin-right: 10px;
            //         // border: 1px solid rgba(255,255, 255, 0.4);
            //     }
            // }
        }

        @media(max-width: 767px) {
            .goalCardWrap {
                padding: 10px;

                .row {
                    margin: 0 -3px;
                }

                .row [class*='col-'] {
                    padding: 0 3px;
                }

                .cardNumberBlock {

                    .cardBlockInner {
                        display: flex;
                        justify-content: space-between;
                        padding: 12px;
                    }

                    .cardText {
                        font-size: var(--font12);
                    }

                }
            }
        }
    }

    // Student Progress
    .studentProgressWrap {
        .mainTitle {
            margin-top: 30px;
            margin-bottom: 25px;
        }

        .progressWrap {
            .progressBlockWrp {
                background: #FFFFFF;
                box-shadow: 0px 4.02759px 8.05517px rgba(0, 0, 0, 0.04), 0px 0px 2.01379px rgba(0, 0, 0, 0.06), 0px 0px 1.0069px rgba(0, 0, 0, 0.04);
                border-radius: 4.02759px;
                padding: 22px;
                margin-bottom: 20px;
                cursor: pointer;

                &.green {
                    border-bottom: 4px solid #6FCC47;
                }

                &.yellow {
                    border-bottom: 4px solid #FFC355;
                }

                &.red {
                    border-bottom: 4px solid #FF6363;
                }
            }

            .progressBlock {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .leftBlock {
                display: flex;
                align-items: center;

                .avatarIcon {
                    margin-right: 7px;
                    width: 89px;
                    height: 89px;
                    border-radius: 8px;
                    object-fit: cover;
                }

                .textBlock {
                    .title {

                        font-weight: var(--font-bold);
                        font-size: var(--font16);
                        line-height: 1.2;
                        letter-spacing: -0.2px;
                        color: var(--primary-font-color);
                        margin-bottom: 5px;
                    }

                    .grade {
                        font-family: var(--secondary-font-family);
                        font-weight: var(--font-600);
                        font-size: var(--font12);
                        line-height: 1.2;
                        letter-spacing: -0.12px;
                        color: #246BF8;
                        margin-bottom: 5px;
                    }

                    .status {
                        display: flex;
                        align-items: center;
                        font-family: var(--secondary-font-family);
                        font-weight: var(--font-medium);
                        font-size: var(--font12);
                        line-height: 1.2;
                        letter-spacing: -0.12px;
                        color: #6A6E83;
                        margin-bottom: 5px;

                        .circleIcon {
                            display: inline-block;
                            width: 6px;
                            height: 6px;
                            // background-color: #6FCC47;
                            border-radius: 50%;
                            margin-right: 5px;

                            &.green {
                                background-color: #6FCC47;
                            }

                            &.yellow {
                                background-color: #FFC355;
                            }

                            &.red {
                                background-color: #FF6363;
                            }

                        }
                    }


                }
            }


        }
    }

}