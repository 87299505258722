.parentProfile {
    .cardHeader {
        font-weight: var(--font-medium);
        font-size: var(--font16);
        line-height: 1.2;
        color: var(--color3);
        text-transform: capitalize;
        &.title-main{
            font-size: var(--font20);
        }
    }
    .custom-lable{
        font-size: var(--font14);
    }
    .form-control{
        font-size: var(--font14);
    }
    .form-group{
        margin-bottom: 20px;
    }
    // Buttons
    .btnWrap{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7px;
        margin-top: 50px;
        margin-bottom: 15px;
        .btnInner{
            padding: 0 7px;
            .formBtn{
                margin-bottom: 10px;
            }
        }
    }
}