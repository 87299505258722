 .home_container{
    height: 100%;
    position: relative;
}

.home-title-container{
    background-color: var(--selected-primary-button-color);
    height: 60px;
    display: flex;
    align-items: center;
}
.home-title-text{
    color: var(--sign-in-text-color);
    font-size: 18px;
    margin-bottom: 0;
    padding: 0 35px;
}
.home-title-text-underline{
    text-decoration: underline;
}
.tabs-container{
    padding:25px 35px;
    height: 100%;
    background-color: var(--background-color); 
    min-height: 100vh;
}
.tabs-button{
    height: 46px;
    flex: 1;
    background-color: var(--selected-primary-button-color);
    outline: none;
    font-weight: 700;
    color: #A68792;
}


/* .query-result-pagination{
    position: absolute;
    bottom: 20;
    padding: 10px 0px;
    right: 35px;
} */
.actives {
    background-color: var(--secondary-color)!important;
    color: white!important;
}
.registration_input {
    width: 100%;
    background-color: #FFF;
    padding: 30px 12px;
    border: 1px solid var(--border-color);
    color: grey;
    border-radius: 1px;
    font-size: 16px;
    height: 40px;
    text-decoration: none;
    outline: 0;
}

.tab-active{
    color: white;
    background-color: var(--secondary-color)!important;
}
.summary-container{
    display: flex;
    padding: 30px 15px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-bottom: 25px;
     border-bottom: 2px solid var(--title-text-color);
 }
 .name-and-grade-seprator{
    border-left: 1px solid #ccc;
    margin: 12px;
}
.summary_text{
    font-size: 18px;
    margin-bottom: 0px;
    text-align: center;
}
.summary-watchlist{
    width: 5%;
}
.summary-student-container{
    display: flex;
    align-items: center;
    cursor: pointer;
     padding: 0 50px;
 }
 .summary-watchlist-container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
.summary-allstudent-image{
    width: 67px;
    height: 46px;
}
.summary-student-text{
    margin-bottom: 0px;
    font-size: 14px;
}
.sepratorr{
    border-left: 1px solid black;
}
.student-progress-container{
    margin: 0px 30px 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.student-progress-container h6{
    color: var(--selected-primary-button-color);
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0 0 30px; 
}
.search-student{
    width: 35%;
    display: flex;
    align-items: center;
}
.student-list-container{
    margin: 15px auto;
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding-left: 30px;
    padding-right: 0px;
}
.success-border{
   border-left: 8px solid var(--success-color);
   border-right: 8px solid var(--success-color);
}
.atrisk-border{
    border-left: 8px solid var(--yellow-color);
    border-right: 8px solid var(--yellow-color);
 }
 .offtrack-border{
    border-left: 8px solid var(--at-risk-color);
    border-right: 8px solid var(--at-risk-color); 
 }
.student-avatar{
    border-radius: 50%;
    width: 60px;
    height:60px
}
.student-name{
    font-size: 17px;
    font-weight: 700;
    color: var(--title-text-color);
    margin-bottom: 0;
    padding: 5px 0 0 30px;
    cursor: pointer;
}
.student-list-icon{
    width: 30px;
    height:30px;
}
.student-container{
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
}
.grade-container{
    margin-left: 60px;
}
.student-msg-container{
    padding: 0;
}
.listContainer{
    margin: 0px 15px;
    padding-bottom: 50px;
}
.counselor-studentlist-tabs-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    cursor: pointer;
}
.counselor-studentlist-tabs{
    height: 46px;
    text-align: center;
    line-height: 46px;
}
.inactive{
    background: var(--selected-primary-button-color);
    color: var(--tab-button-color);
    height: 46px;
    font-weight: bold;  
}
@media screen and (max-width:1024px){
    .summary-student-container{
        padding: 0px;
    }
    .home-title-text{
        padding: 0 15px;
    }
    .student-list-container{
        padding-left: 0;
        padding-right: 15px;
    }

    .name-and-grade-seprator{
        display: none;
    }
    .summary-container{
        padding: 10px 0 ;
    }
    .home-title-container{
        height: auto;
        padding: 10px;
    }
    .summary-watchlist{
        width: 100%;
        padding-bottom: 15px;
    }
    .summary-watchlist-container{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 15px;
    } 
    .search-student{
        width: 100%;
    }
    .student-progress-container{
        flex-wrap: wrap;
    }
    .grade-container{
        margin-left: 0px;
    }
    .student-name-container{
        justify-content: center;
    }
    .student-msg-container{
        padding:10px 0;
        width: 100%;
    }
    .tabs-container{
        padding: 25px 10px;
    }
    .student-name{
        padding:5px 0 0 5px;
        width: min-content;
    }
    .counselor-name-image-container{
        width: 100%;
    }
    .counselor-goal-status{
        width:100%;
    }
    .counselor-student-container{
        /* position: absolute; */
        bottom: 0px;
    }
    .listContainer{
        margin: 0px;
    }
    .summary-allstudent-image{
        width: 60px;
        height: 40px;
        padding-left: 10px;
    }
} 