.app-logo {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/logo.png");
  width: 288px;
  height: 52px;
}

.signin-title {
  font-family: var(--ternary-font-family);
  font-weight: var(--font-bold);
  font-size: var(--font32);
  line-height: 1.2;
  color: var(--color6);
  margin-top: 5px;
  margin-bottom: 50px;
}
.text-left > input {
 color: black !important;
}
.right-box {
  background-color: #f5f6f7;
}

.slide-img-bg {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/Mask group.png");
  opacity: 1;
}

.form-control {
  border-radius: 10px;
  height: calc(1.5em + 0.75rem + 10px);
  background: #f5f6f7;
  border: 1px solid rgba(28, 28, 28, 0.1);
}

.loginContainer::-webkit-scrollbar {
  display: none;
}

.loginForm label,
.loginForm input {
  font-weight: var(--font-medium);
  font-size: var(--font14);
  line-height: 1.2;
  color: var(--color6);
}

.loginForm .loginInput {
  color: var(--secondary-color);
  min-height: 50px;
}

.loginArea {
  max-width: 524px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.registerLinkWrap {
  margin-top: 50px;
  margin-bottom: 20px;
}

.registerLinkWrap1 {
  margin-top: 160px;
  margin-bottom: 20px;
}

.registerLink {
  text-decoration: none;
  color: #049804;
}
.privacyText{
  color: rgb(8, 58, 191);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}
.privacyText:hover {
  color: #049804;
}
.registerLink:hover {
  text-decoration: none;
  color: rgb(8, 58, 191);
}

@media(max-width: 991px) {
  .loginArea{
    max-width: 100%;
  }
  .loginContainer .row {
    /* height: 100% !important; */
    overflow-y: auto !important;
  }

  .signin-title {
    margin-top: 15px;
    margin-bottom: 22px;
    font-size: 24px;
  }
}