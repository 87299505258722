.template-container {
    margin: 0px 25px;
}

.title-container {
    font-size: 18px;
    padding: 10px;
    font-weight: bold;
    margin-bottom: 0;
}

.template-header {
    display: flex;
    border-bottom: 1px solid #333;
    align-items: center;
    padding: 10px 0;
}

.template-icon {
    width: 52px;
    height: 52px;
    margin: 20px;
}

.template-count {
    font-size: 24px;
    color: #333;
    margin-bottom: 0;
}

.template-label {
    font-size: 14px;
}

.template-grid-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 40px;
    margin-top: 20px;
}

.template-item {
    border: 1px solid #e0e0e0;
    background-color: #e8f5e9;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    transition: all 0.2s ease;
}

.template-item:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.template-subject {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.template-section {
    font-size: 18px;
    font-weight: 800;
    color: green;
    margin-bottom: 10px;
}

.template-body {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.language-buttons {
    display: flex;
    gap: 10px;
}

.lang-btn {
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px;
    min-width: 45px;
    text-align: center;
}

.lang-btn.active {
    background: #4CAF50;
    color: white;
    border-color: #4CAF50;
}

.lang-btn:hover:not(.active) {
    background: #e8f5e9;
    border-color: #4CAF50;
}

.template-creation-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.template-creation-header {
    border-bottom: 2px solid #333;
    padding: 12px 16px;
    margin: -20px -20px 20px;
    background: #f8f8f8;
    border-radius: 8px 8px 0 0;
}

.template-creation-header h2 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.template-form-group {
    margin-bottom: 20px;
}

.language-select,
.section-select {
    width: 100%;
    height: 45px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
    transition: all 0.2s ease;
}

.subject-input,
.body-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 14px;
    transition: all 0.2s ease;
}

.feedback-section {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-top: 15px;
}

.feedback-checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.feedback-input {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.template-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.save-template-button,
.delete-template-button {
    padding: 10px 24px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.2s ease;
    min-width: 150px;
}

.save-template-button {
    background: #4CAF50;
    color: white;
    border: none;
}

.save-template-button:hover {
    background: #43A047;
}

.delete-template-button {
    background: white;
    color: #dc3545;
    border: 1px solid #dc3545;
}

.delete-template-button:hover:not(:disabled) {
    background: #dc3545;
    color: white;
}

.delete-template-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.translating-text {
    color: #737373;
    font-size: 17px;
    margin: 5px 0;
}